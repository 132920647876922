<template>
  <div>
    消息
  </div>
</template>

<script>
export default {
  name: "Messages",
  data(){
    return{

    }
  },
  mounted() {
    // 获取消息
  }
}
</script>

<style scoped>

</style>